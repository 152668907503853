<template>
  <div>
    <ToolbarQuill
      v-if="toolbarsId"
      :toolbars-id="toolbarsId"
      :for-toolbar="true"
      @selectEmoji="onSelectEmoji"
      @formatText="onFormatText"
    />
    <div v-if="variable">
      <div
        class="flex flex-wrap mb-1 gap-2"
      >
        <div
          v-for="(item, index) in additionalVariable"
          :key="index"
        >
          <b-button
            pill
            variant="outline-primary"
            size="sm"
            @click="addVariable(item.var)"
          >
            {{ item.var }}
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-if="forSpintax"
      class="flex items-center  border !border-b-0 rounded-md rounded-br-none rounded-bl-none justify-between"
      :class="[editable? 'px-[12px]' : 'p-[12px]', {'border-primary border-b-0': checked}]"
    >
      <div class="flex gap-5 items-center">
        <div class="font-bold text-[14px]">
          {{ variantName }}
        </div>
        <ToolbarQuill
          v-if="variantName !== 'Pesan Kamu' && checked && editable"
          :for-toolbar="false"
          :is-editor-enabled="isEditorEnabled"
          @selectEmoji="onSelectEmoji"
          @formatText="onFormatText"
        />
      </div>
      <div v-if="variantName !== 'Pesan Kamu' && checked">
        <div v-if="!editable">
          <b-button
            size="sm"
            variant="primary"
            class=""
            @click="handleEdit"
          > Edit
          </b-button>
        </div>
        <div
          v-else
          class="flex gap-1"
        >
          <b-button
            size="sm"
            variant="white"
            class=""
            @click="handleCancel"
          > Batal
          </b-button>
          <b-button
            size="sm"
            :disabled="characterCount < 20"
            variant="primary"
            class=""
            @click="handleEdit"
          > Simpan
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex items-center justify-between"
    >
      <ToolbarQuill
        :for-toolbar="false"
        :is-editor-enabled="isEditorEnabled"
        @selectEmoji="onSelectEmoji"
        @formatText="onFormatText"
      />
      <div
        v-if="spintaxContent.length"
        class="flex bg-[#F4F4F4] rounded h-fit w-fit items-center p-[4px] gap-1"
      >
        <button
          :disabled="indexVariant === 0"
          class="px-[4px] py-[2px] rounded "
          :class="indexVariant === 0 ? 'bg-[#F4F4F4]': 'bg-white' "
          @click="handleMoveVariant('min')"
        >
          <b-icon
            icon="chevron-left"
          />
        </button>
        <span class="mx-1 text-sm">{{ variantNames }}</span>
        <button
          :disabled="indexVariant === spintaxContent.length -1"
          :class="indexVariant === spintaxContent.length - 1 ? 'bg-[#F4F4F4]': 'bg-white' "
          class="px-[4px] rounded py-[2px] "
          @click="handleMoveVariant('plus')"
        >
          <b-icon
            icon="chevron-right"
          />
        </button>
      </div>
    </div>

    <div
      :class="[customClass,{'bg-[#F4F4F4]': !isEditorEnabled}, {'border-primary': checked} ]"
      class="rounded-md border-t-0 border"
    >
      <quill-editor
        ref="quill"
        v-model="computedContent"
        :options="editorOptions"
        @ready="onEditorReady($event)"
      />
    </div>
    <div
      v-if="maxLength"
      class=" relative text-right text-sm text-[#828282]"
    >
      {{ characterCount }}/{{ maxLength }}
    </div>
  </div>
</template>
<script>
import ToolbarQuill from '@/views/pages/komship/customer/Broadcast/components/ToolbarQuill.vue'
import { quillEditor } from 'vue-quill-editor'
import { additionalVariable } from '../config'

/* eslint-disable import/no-extraneous-dependencies */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'

export default {
  components: {
    quillEditor,
    ToolbarQuill,
  },
  props: {
    editorOptions: {
      type: Object,
      default: () => ({
        theme: 'bubble',
        placeholder: 'Masukan Teks Kamu Disini',
        modules: {
          toolbar: '',
        },
      }),
    },
    toolbarsId: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: null,
    },
    variable: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: 'min-h-[273px]',
    },
    isEditorEnabled: {
      type: Boolean,
      default: true,
    },
    forSpintax: {
      type: Boolean,
      default: false,
    },
    variantName: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    spintaxContent: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      quill: null,
      characterCount: 0,
      additionalVariable,
      editable: false,
      variantNames: 'Utama',
      indexVariant: 0,
    }
  },
  computed: {
    computedContent: {
      get() {
        return this.content
      },
      set(value) {
        this.$emit('update:content', value)
      },
    },
  },
  watch: {
    content(newValue) {
      const actualLength = this.quill.getText().trim().length
      this.characterCount = actualLength
      this.$emit('update:length', actualLength)
    },
    isEditorEnabled(newValue) {
      this.quill.enable(newValue)
    },
    checked(newValue) {
      if (newValue === false) {
        this.editable = false
      }
    },
    spintaxContent(newValue) {
      if (newValue.length > 0) {
        this.quill.enable(false)
      } else {
        this.quill.enable(true)
      }
    },
  },
  mounted() {
    if (!this.isEditorEnabled || this.forSpintax) {
      this.quill.enable(false)
    }
  },
  methods: {
    handleMoveVariant(value) {
      const totalIndex = this.spintaxContent.length
      if (value === 'plus' && this.indexVariant < totalIndex) {
        this.indexVariant += 1
      } else if (value === 'min' && this.indexVariant > 0) {
        this.indexVariant -= 1
      }
      this.content = this.spintaxContent[this.indexVariant].content
      this.variantNames = this.indexVariant === 0 ? 'Utama' : `Variant ${this.indexVariant}`
    },
    toggleEditor(isEnabled) {
      if (this.quill) {
        this.quill.enable(isEnabled)
      }
    },
    onFormatText(format) {
      if (this.quill) {
        const isFormatActive = this.quill.getFormat()[format]
        this.quill.format(format, !isFormatActive)
      } else {
        console.warn('Quill editor belum siap')
      }
    },
    onEditorReady(quill) {
      this.quill = quill
    },
    onSelectEmoji(dataEmoji) {
      const { quill } = this.$refs['quill-editor']
      const selection = quill.getSelection()
      const index = selection ? selection.index : 0
      quill.insertText(index, dataEmoji.data)
      quill.setSelection(index + dataEmoji.data.length)
      this.computedContent = this.quill.root.innerHTML
    },
    addVariable(value) {
      const types = 'quill'
      const editorEl = this.$refs[types].$el

      // Get the current selection and range
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)

      const caretIsOutsideEditor = !editorEl.contains(
        range.commonAncestorContainer,
      )
      if (caretIsOutsideEditor) {
        return
      }
      // Create a text node with the desired text
      const textNode = document.createTextNode(value)
      // Insert the text node at the cursor position
      range.insertNode(textNode)
      // Move the cursor to the end of the inserted text
      range.setStartAfter(textNode)
      range.setEndAfter(textNode)
      // Clear the current selection and set the new range
      selection.removeAllRanges()
      selection.addRange(range)
      // Set focus back to the contenteditable element
      editorEl.focus()
    },
    handleEdit() {
      this.editable = !this.editable
      this.toggleEditor(this.editable)
      this.$emit('update:edit', this.editable)
    },
    handleCancel() {
      this.editable = !this.editable
      this.toggleEditor(this.editable)
      this.$emit('handleCancel', this.editable)
      this.$emit('update:edit', this.editable)
    },
  },
}

</script>
